import i18n from 'localization/Localization';
import { ETRACKER_SECURE_CODE, FB_PIXEL_ID, GA_TRACKING_ID } from '../config';
import { loadEtracker } from './etrackr';
import { initFBPixel, loadFBPixel } from './fbPixel';
import { loadGtag } from './gtag';
import { GoogleAnalyticsInitializer } from './initialization/GoogleAnalyticsInitializer';
import { TrackerUtil } from './TrackerUtil';
import initABTesting from '../abTesting';

/**
 * This service is responsible for setting up and managing all tracking related functionalities.
 * It loads all the necessary tracking scripts asynchronously, including etracker, Google Analytics (gtag), Facebook Pixel, LinkedIn Pixel, and Programmatic Retargeting Pixel.
 * It also provides methods to setup tracking and to track pages and events.
 *
 * The tracking data is managed by the TrackerUtil.
 */
const TrackingService = () => {
  /**
   * Asynchronously loads all necessary tracking scripts.
   *
   * This includes:
   * - etracker
   * - PsoTracking
   * - Google Analytics (gtag)
   * - Facebook Pixel
   * - LinkedIn Pixel
   * - Programmatic Retargeting Pixel
   *
   * Each script is loaded and then initialized if necessary.
   *
   * @returns {Promise} A promise that resolves when all scripts have been loaded and initialized.
   */
  const loadTrackingScripts = async () => {
    const abTesting = initABTesting();

    return Promise.all([
      loadEtracker(false, 'onboarding', abTesting, ETRACKER_SECURE_CODE).catch((err) =>
        console.error(err)
      ),
      loadGtag(GA_TRACKING_ID),
      loadFBPixel(FB_PIXEL_ID)
        .then((initNeeded) => initFBPixel(initNeeded, FB_PIXEL_ID))
        .catch((err) => console.error(err)),
      i18n
    ]);
  };

  /**
   * Sets up tracking for Google Analytics.
   *
   * This involves creating a new instance of GoogleAnalyticsInitializer and starting it.
   */
  function setupTracking() {
    new GoogleAnalyticsInitializer().start();
  }

  /**
   * Tracks a page view.
   *
   * If a page name is provided, it is used. Otherwise, the current page name is retrieved from TrackerUtil.
   *
   * @param {string} [pageName] - The name of the page to track. If not provided, the current page name is used.
   */
  function trackPage(pageName?: string) {
    TrackerUtil.trackPage(pageName ?? TrackerUtil.getPageName());
  }

  /**
   * Tracks a specific event.
   *
   * This involves providing details about the event such as the page name, category, action, type, and an optional value.
   * These details are then passed to the TrackerUtil's trackEvent method.
   *
   * @param {string} pageName - The name of the page where the event occurred.
   * @param {string} category - The category of the event.
   * @param {string} action - The action that was performed.
   * @param {string} type - The type of the event.
   * @param {string} [value] - An optional value associated with the event.
   */
  function trackEvent(
    pageName: string,
    category: string,
    action: string,
    type: string,
    value?: string
  ) {
    TrackerUtil.trackEvent(pageName, category, action, type, value);
  }

  // Return the tracking functions so they can be used elsewhere
  return { loadTrackingScripts, setupTracking, trackPage, trackEvent };
};

export default TrackingService;
