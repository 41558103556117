import { AppointmentClient } from 'clients';
import { PreferredTimeSlot, AvailableDaySlot as AvailableDays } from 'clients/obc/types';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Order, OrderType, TimeOfDay } from 'reducers/onboardingInformationSlice';
import { RootState } from 'reducers/rootReducer';

import { PublicHolidays } from 'services/tools/PublicHolidayProvider';
import {
  DAY_OF_DATE_INDEX_FRIDAY,
  PREFERRED_TIMESLOT_ALL_DAY_APARTMENT_THRESHOLD
} from 'tools/Constants';
import useDuration from 'tools/hooks/useDuration';
import { AppointmentType } from './OrtecAvailabilityContext';
import {
  PreferredTimeSlotCandidate,
  PreferredTimeSlotContextType,
  PreferredTimeSlotIndex,
  TimeData
} from 'contexts';

interface Props {
  appointmentType: AppointmentType;
}

const DEFAULT_MAX_DAY_OFFSET = 28; // 4 weeks
const NUMBER_OF_ADVANCED_DAYS_FOR_INSTALLATION = 60;
const NUMBER_OF_ADVANCED_DAYS_FOR_ONSITE_INSPECTION = 16;

const INITIAL_SELECTED_TIMESLOTS: PreferredTimeSlot[] = [
  { date: undefined, timeOfDay: undefined },
  { date: undefined, timeOfDay: undefined },
  { date: undefined, timeOfDay: undefined }
];

function addDays(date: Date, days: number) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function calculateInitialDates(appointmentType: AppointmentType): {
  min: Date;
  max: Date;
} {
  const offsetInDays =
    appointmentType === 'installation'
      ? NUMBER_OF_ADVANCED_DAYS_FOR_INSTALLATION
      : NUMBER_OF_ADVANCED_DAYS_FOR_ONSITE_INSPECTION;

  let initialMinDate = new Date();
  initialMinDate.setHours(0, 0, 0, 0);
  initialMinDate = addDays(initialMinDate, offsetInDays);

  let initialMaxDate = new Date();
  initialMaxDate.setHours(0, 0, 0, 0);
  initialMaxDate = addDays(initialMaxDate, DEFAULT_MAX_DAY_OFFSET + offsetInDays);

  return {
    min: initialMinDate,
    max: initialMaxDate
  };
}

export const PreferredTimeSlotContext = createContext({} as PreferredTimeSlotContextType);

const PreferredTimeSlotProvider: React.FC<Props> = ({ children, appointmentType }) => {
  const [state, setState] = useState<'intial' | 'loaded'>('intial');
  // calendar days
  const [unavailableDays, setUnavailableDays] = useState<Date[]>([]);
  const [dateRange, setDateRange] = useState<{ min: Date; max: Date }>(
    calculateInitialDates(appointmentType)
  );

  const [availableTimeSlotsDatas, setAvailableTimeSlotsDatas] = useState<AvailableDays[]>([]);

  // chosen date in calendar
  const [dateCandidate, setDateCandidate] = useState<Date | undefined>(undefined);
  // chosen timeslot
  const [preferredTimeSlotCandidate, setPreferredTimeSlotCandidate] =
    useState<PreferredTimeSlotCandidate>(undefined);

  const [preferredTimeData, setPreferredTimeData] = useState<TimeData | undefined>(undefined);

  const [preferredTimeslotIndex, setPreferredTimeslotIndex] = useState<PreferredTimeSlotIndex>(
    PreferredTimeSlotIndex.None
  );

  const [preferredTimeslots, setPreferredTimeslots] = useState<PreferredTimeSlot[]>(
    INITIAL_SELECTED_TIMESLOTS
  );

  const {
    onSiteInspectionInterval,
    installationInterval,
    onSiteInspectionIntervalWithoutDI,
    installationIntervalWithoutDI
  } = useSelector(({ onboardingInfo }: RootState) => {
    const userData = onboardingInfo!.userData;
    const index = onboardingInfo!.selectedPropertyIndex;

    const property = userData.properties[index];
    const order = property.orders.find((order: Order) => {
      return order.type === OrderType.TechemDirect;
    });

    const onSiteInspectionIntervalWithoutDI =
      property.onSiteInspectionAppointmentAdvanceDays !== undefined
        ? {
            from: new Date(
              new Date(
                new Date().setDate(
                  new Date(new Date()).getDate() + property.onSiteInspectionAppointmentAdvanceDays
                )
              ).setHours(0, 0, 0, 0)
            ),

            to: new Date(
              new Date().setDate(
                new Date(new Date().setHours(0, 0, 0, 0)).getDate() +
                  property.onSiteInspectionAppointmentAdvanceDays +
                  DEFAULT_MAX_DAY_OFFSET
              )
            )
          }
        : undefined;

    const installationIntervalWithoutDI =
      property.installationAppointmentAdvanceDays !== undefined
        ? {
            from: new Date(
              new Date(
                new Date().setDate(
                  new Date(new Date()).getDate() + property.installationAppointmentAdvanceDays
                )
              ).setHours(0, 0, 0, 0)
            ),
            to: new Date(
              new Date().setDate(
                new Date(new Date().setHours(0, 0, 0, 0)).getDate() +
                  property.installationAppointmentAdvanceDays +
                  DEFAULT_MAX_DAY_OFFSET
              )
            )
          }
        : undefined;

    return {
      onSiteInspectionInterval: order?.onSiteInspectionInterval,
      installationInterval: order?.installationInterval,
      onSiteInspectionIntervalWithoutDI: onSiteInspectionIntervalWithoutDI,
      installationIntervalWithoutDI: installationIntervalWithoutDI
    };
  });

  const { numberOfApartments } = useDuration(appointmentType);

  // eslint-disable-next-line
  const handleSetDateCandidate = (dates: {
    readonly date: Date | (Date | null | undefined)[] | null | undefined;
  }) => {
    setDateCandidate(
      !!dates.date ? ((Array.isArray(dates.date) ? dates.date[0] : dates.date) as Date) : undefined
    );
    setPreferredTimeSlotCandidate(undefined);
    setPreferredTimeData(undefined);
  };

  const resetPreferredTimeslotIndex = () => {
    setPreferredTimeslotIndex(PreferredTimeSlotIndex.None);
    setDateCandidate(undefined);
    setPreferredTimeSlotCandidate(undefined);
  };

  const submitTimeslots = (
    appointmentType: AppointmentType,
    orderId: string,
    timeslots: PreferredTimeSlot[]
  ) => {
    if (appointmentType === 'installation')
      return AppointmentClient.setInstallationPreferredTimeslots(orderId, timeslots);

    return AppointmentClient.setOnSiteInspectionPreferredTimeslots(orderId, timeslots);
  };

  /** Search and return the timeslots for the current date candidate */
  const filterAvailableTimeSlots = useCallback(
    (timeSlots: AvailableDays[], dateCandidate: Date) => {
      // get date format without hours, mintutes etc..
      const searchedDate = new Date(dateCandidate.setHours(0, 0, 0, 0));
      let ts =
        timeSlots.find((item) => {
          const itemDate = new Date(item.date.setHours(0, 0, 0, 0));

          if (item.date && dateCandidate) return itemDate.valueOf() === searchedDate.valueOf();
          return false;
        })?.availableTimeslots || [];

      if (
        appointmentType === 'installation' &&
        numberOfApartments >= PREFERRED_TIMESLOT_ALL_DAY_APARTMENT_THRESHOLD
      )
        ts = ts.filter((timeslot) => {
          return timeslot.timeOfDay === TimeOfDay.AllDay;
        });

      if (appointmentType === 'inspection')
        ts = ts.filter((timeslot) => {
          if (new Date(timeslot.from).getDay() !== DAY_OF_DATE_INDEX_FRIDAY) return true;
          return timeslot.timeOfDay === TimeOfDay.Morning;
        });

      return ts;
    },
    [appointmentType, numberOfApartments]
  );

  /** Get the days with already picked timeslots  */
  const getDaysWithUsedTimeslots = useCallback(
    (
      preferredTimeslots: PreferredTimeSlot[],
      preferredTimeslotIndex: number,
      minDate: Date
    ): Date[] => {
      return preferredTimeslots
        .map(
          (timeSlot) =>
            timeSlot.date !== undefined
              ? timeSlot.date
              : new Date(new Date(new Date().setDate(minDate.getDate() - 1)).setHours(0, 0, 0, 0)) // default is yesterday
        )
        .filter((date) => {
          return (
            date !== undefined &&
            preferredTimeslots[preferredTimeslotIndex] !== undefined &&
            new Date(date).setHours(0, 0, 0, 0) !==
              preferredTimeslots[preferredTimeslotIndex].date?.valueOf()
          );
        });
    },
    []
  );

  /** Create an array with prefilled timeslots for each available days. Excludes already picked Timeslots. */
  const fillAvailableDays = useCallback(
    (minDate: Date, maxDate: Date): AvailableDays[] => {
      let availableDaySlots: AvailableDays[] = [];
      const maxDateValue = new Date(new Date(maxDate).setHours(0, 0, 0, 0)).valueOf();

      for (
        let tmpDate = new Date(new Date(minDate).setHours(0, 0, 0, 0));
        tmpDate.valueOf() <= maxDateValue;
        tmpDate = new Date(new Date(tmpDate).setDate(tmpDate.getDate() + 1))
      ) {
        const currentDateValue = new Date(new Date(tmpDate).setHours(0, 0, 0, 0)).valueOf();

        const timeslotDateValues = preferredTimeslots.map((timeSlot) => {
          if (timeSlot.date === undefined) return undefined;
          return new Date(new Date(timeSlot.date!).setHours(0, 0, 0, 0)).valueOf();
        });

        if (
          preferredTimeslots[preferredTimeslotIndex] !== undefined &&
          currentDateValue === preferredTimeslots[preferredTimeslotIndex].date?.valueOf()
        ) {
          if (
            preferredTimeslots[preferredTimeslotIndex] !== undefined &&
            timeslotDateValues.includes(currentDateValue) &&
            currentDateValue !== preferredTimeslots[preferredTimeslotIndex].date?.valueOf()
          ) {
            continue;
          }
        }

        let potentialTimeSlots: Array<{
          from: string;
          till: string;
          timeOfDay: TimeOfDay;
        }> = [
          {
            from: new Date(new Date(tmpDate).setHours(8)).toString(),
            till: new Date(new Date(tmpDate).setHours(16, 30)).toString(),
            timeOfDay: TimeOfDay.AllDay
          },
          {
            from: new Date(new Date(tmpDate).setHours(8)).toString(),
            till: new Date(new Date(tmpDate).setHours(12)).toString(),
            timeOfDay: TimeOfDay.Morning
          },
          {
            from: new Date(new Date(tmpDate).setHours(12)).toString(),
            till: new Date(new Date(tmpDate).setHours(16, 30)).toString(),
            timeOfDay: TimeOfDay.Afternoon
          }
        ];

        availableDaySlots = [
          ...availableDaySlots,
          {
            date: tmpDate,
            availableTimeslots: potentialTimeSlots
          }
        ];
      }

      // remove all fridays if type is installation
      return appointmentType === 'inspection'
        ? availableDaySlots
        : availableDaySlots.filter((slot: AvailableDays) => {
            return slot.date.getDay() !== 5;
          });
    },
    [preferredTimeslots, preferredTimeslotIndex, appointmentType]
  );

  /** (Re-)fills the array with available days and filter if the user picks a new "Terminauswahl"-element */
  useEffect(() => {
    let availableTimeSlots = fillAvailableDays(dateRange.min, dateRange.max);
    let unavailableDates = getDaysWithUsedTimeslots(
      preferredTimeslots,
      preferredTimeslotIndex,
      dateRange.min
    );

    setAvailableTimeSlotsDatas(availableTimeSlots);
    setUnavailableDays([...unavailableDates, ...PublicHolidays]);
  }, [
    preferredTimeslots,
    preferredTimeslotIndex,
    dateRange,
    fillAvailableDays,
    getDaysWithUsedTimeslots
  ]);

  useEffect(() => {
    // assure that this Effect only used once
    //(needed because default dates in dateRange)
    if (state === 'loaded') return;

    /** Ensure calendar starts at the next free workday, i.e. also moves on to the next month if the last day of the previous month was already picked. */
    const calcMinDate = (minDate: Date): Date => {
      // earliest Date is the next day

      // exclude current date
      if (minDate === new Date()) {
        minDate = new Date();
        minDate.setHours(0, 0, 0, 0);
        minDate = addDays(minDate, 1);
      }

      let tomorrow = new Date();
      tomorrow.setHours(0, 0, 0, 0);
      tomorrow = addDays(tomorrow, 1);

      // If interval already has started, return the date of tomorrow
      if (tomorrow > minDate) return tomorrow;

      while (
        [0, 6].includes(minDate.getDay()) || //exclude weekend days 0: Saturday, 1: Sunday
        !!preferredTimeslots
          .filter((preferredTimeslot) => !!preferredTimeslot.date)
          .map((preferredTimeslot) => preferredTimeslot.date!.valueOf())
          .includes(minDate.valueOf())
      ) {
        minDate = addDays(minDate, 1);
      }
      return minDate;
    };

    const interval =
      appointmentType === 'installation' ? installationInterval : onSiteInspectionInterval;

    const intervalWithoutDI =
      appointmentType === 'installation'
        ? installationIntervalWithoutDI
        : onSiteInspectionIntervalWithoutDI;

    let newMinDate = interval?.from
      ? calcMinDate(new Date(interval.from))
      : intervalWithoutDI?.from
      ? calcMinDate(intervalWithoutDI.from)
      : calcMinDate(dateRange.min);
    let newMaxDate = interval?.to ? new Date(interval.to) : intervalWithoutDI?.to ?? dateRange.max;

    let availableTimeSlots = fillAvailableDays(newMinDate, newMaxDate);
    let unavailableDates = getDaysWithUsedTimeslots(
      preferredTimeslots,
      preferredTimeslotIndex,
      newMinDate
    );

    if (availableTimeSlots.length > 0)
      // Get earleast date from
      newMinDate = availableTimeSlots.reduce(function (pre, cur) {
        return pre.date.valueOf() > cur.date.valueOf() ? cur : pre;
      }).date;

    setDateRange(() => {
      return { min: newMinDate, max: newMaxDate };
    });

    setAvailableTimeSlotsDatas(availableTimeSlots);
    setUnavailableDays([...unavailableDates, ...PublicHolidays]);
    setState('loaded');
  }, [
    appointmentType,
    installationInterval,
    onSiteInspectionInterval,
    installationIntervalWithoutDI,
    onSiteInspectionIntervalWithoutDI,
    preferredTimeslotIndex,
    preferredTimeslots,
    state,
    dateRange,
    fillAvailableDays,
    getDaysWithUsedTimeslots
  ]);

  // Provider Data
  const contextData = {
    minDate: dateRange.min,
    maxDate: dateRange.max,
    unavailableDays,
    handleSetDateCandidate,
    availableTimeSlotsDatas,

    filterAvailableTimeSlots,

    preferredTimeslots,
    setPreferredTimeslots,

    preferredTimeslotIndex,
    setPreferredTimeslotIndex,
    resetPreferredTimeslotIndex,

    preferredTimeSlotCandidate,
    setPreferredTimeSlotCandidate,

    preferredTimeData,
    setPreferredTimeData,

    submitTimeslots,

    onSiteInspectionInterval,
    installationInterval,

    dateCandidate,
    numberOfApartments
  };

  return (
    <PreferredTimeSlotContext.Provider value={contextData}>
      {children}
    </PreferredTimeSlotContext.Provider>
  );
};

export default PreferredTimeSlotProvider;
