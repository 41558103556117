import { apiClient } from 'lib/axios';

import {
  //generateURLQueryForAPILoginCall,
  generateURLQueryForAPILogoutCall
} from './authUtils';

export const AuthPaths = {
  Login: '/oauth2/login',
  Logout: '/oauth2/logout'
};

const AuthClient = {
  login() {
    // TODO: Remove after login is fixed.
    // Aktuelle URL abrufen
    const currentUrl = window.location.href;
    // Extrahiere den Teil der URL vor "#/"
    const baseUrl = currentUrl.split('#/')[0];
    // Generiere die neue URL durch Hinzufügen von "/login-error"
    const newUrl = baseUrl + '#/login-error';
    // Setze die neue URL als href der aktuellen Seite
    window.location.href = newUrl;

    // TODO: Enable again after login is fixed.
    // const urlQuery = generateURLQueryForAPILoginCall();
    // const location = `${apiClient.defaults.baseURL}${AuthPaths.Login}${urlQuery}`;
    // window.location.href = location;
  },

  logout() {
    const urlQuery = generateURLQueryForAPILogoutCall();
    const location = `${apiClient.defaults.baseURL}${AuthPaths.Logout}${urlQuery}`;
    window.location.href = location;
  }
};

export default AuthClient;
