import { GA_TRACKING_ID } from '../../config';
import BaseInitializer from './BaseInitializer';

/**
 * This class is responsible for initializing Google Analytics.
 * It extends the BaseInitializer abstract class and implements the start and init methods.
 */
class GoogleAnalyticsInitializer extends BaseInitializer {
  /**
   * This method starts the initialization of Google Analytics.
   */
  start(): void {
    this.init();
  }

  /**
   * This method initializes Google Analytics.
   * It defines the dataLayer and the gtag function.
   * It sets the default consent to 'denied' as a placeholder.
   * It configures gtag.
   */
  protected init(): void {
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    // Set default consent to 'denied' as a placeholder
    // Determine actual values based on your own requirements
    window.gtag('consent', 'default', {
      ad_storage: 'denied',
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      analytics_storage: 'denied'
    });

    // Configure gtag
    window.gtag('js', new Date());
    window.gtag('config', GA_TRACKING_ID);
  }
}

export { GoogleAnalyticsInitializer };
